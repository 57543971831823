interface AmortizationTableProps {
  rows: {
    totalProd: string;
    autoConsumptionRate: string;
    autoConsumptionRatePrice: string;
    autoConsumptionRateCharge: string;
    resaleRate: string;
    resaleRatePrice: string;
    resaleRateCharge: string;
    annualCharge: string;
    annualBalance: string;
    cumulativeBalance: string;
  }[];
}

function numberToEuro(value: number, fixed: number = 2) {
  return `${value.toFixed(fixed)}€`;
}

export function genereateAmortizationTable(
  initial_annual_power_produce_total: number,
  installation_price: number,
  prime: number,
  initial_price_electricity: number = 0.26,
  initial_sell_electricity: number = 0.04,
  number_years: number = 20,
  coeff_autoconsommation: number = 0.55,
  annual_charge: number = 50,
  loose_production: number = 0.01
) {
  let annual_power_produce_total = initial_annual_power_produce_total;
  let price_electricity = initial_price_electricity;
  let sell_electricity = initial_sell_electricity;
  const coeff_sell = 1 - coeff_autoconsommation;
  let cumulativeBalance = -installation_price;

  let rows = [];
  for (let i = 0; i < number_years; i++) {
    if (i > 0) {
      annual_power_produce_total *= 1 - loose_production; // Decrease production by 1% each year
      price_electricity *= 1.05; // Increase price of electricity by 5% each year
      sell_electricity *= 1.02; // Increase price of electricity by 5% each year
    }
    const annualBalance =
      annual_power_produce_total * coeff_autoconsommation * price_electricity +
      annual_power_produce_total * coeff_sell * sell_electricity -
      annual_charge +
      (i > 0 ? 0 : prime);
    cumulativeBalance += annualBalance;
    rows.push({
      totalProd: annual_power_produce_total.toFixed(2),
      autoConsumptionRate: (
        annual_power_produce_total * coeff_autoconsommation
      ).toFixed(2),
      autoConsumptionRatePrice: numberToEuro(price_electricity),
      autoConsumptionRateCharge: numberToEuro(
        annual_power_produce_total * coeff_autoconsommation * price_electricity
      ),
      resaleRate: (annual_power_produce_total * coeff_sell).toFixed(2),
      resaleRatePrice: numberToEuro(sell_electricity, 4),
      resaleRateCharge: numberToEuro(
        annual_power_produce_total * coeff_sell * sell_electricity
      ),
      annualCharge: `${annual_charge.toFixed(2)}€`,
      annualBalance: numberToEuro(annualBalance),
      cumulativeBalance: numberToEuro(cumulativeBalance),
    });
  }
  return rows;
}

export function AmortizationTable({ rows }: AmortizationTableProps) {
  const investissement = getInvestissementBalancePositive(rows);

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden rounded-xl">
            <table className="min-w-full text-left text-sm font-light text-surface">
              <thead>
                <tr className="border border-neutral-200 font-medium bg-gray-300">
                  <th scope="col" rowSpan={2} className="px-6 py-4 border">
                    Année
                  </th>
                  <th scope="col" rowSpan={2} className="px-6 py-4 border">
                    Prod TOTAL kWh
                  </th>
                  <th scope="col" colSpan={3} className="px-6 py-4 border">
                    Taux autoconsommation = 55%
                  </th>
                  <th scope="col" colSpan={3} className="px-6 py-4 border">
                    Taux revente = 45%
                  </th>
                  <th scope="col" rowSpan={2} className="px-6 py-4 border">
                    Charge annuelle
                  </th>
                  <th scope="col" rowSpan={2} className="px-6 py-4 border">
                    Solde Annuel
                  </th>
                  <th scope="col" rowSpan={2} className="px-6 py-4 border">
                    Solde cumulé / Prix de l'installation
                  </th>
                </tr>
                <tr className="border-b border-neutral-200 font-medium bg-gray-300">
                  <th scope="col" className="px-6 py-4 border">
                    kWh
                  </th>
                  <th scope="col" className="px-6 py-4 border">
                    €/kWh
                  </th>
                  <th scope="col" className="px-6 py-4 border">
                    €
                  </th>
                  <th scope="col" className="px-6 py-4 border">
                    kWh
                  </th>
                  <th scope="col" className="px-6 py-4 border">
                    €/kWh
                  </th>
                  <th scope="col" className="px-6 py-4 border">
                    €
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr
                    className={`border-b border-neutral-200 ${
                      index % 2 === 1 && "bg-gray-100"
                    }  hover:border-4 hover:border-neutral-700 ${
                      index >= investissement - 1 &&
                      index <= investissement &&
                      "bg-green"
                    }`}
                  >
                    <td className="whitespace-nowrap px-6 py-4 font-medium border">
                      {index + 1}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 border">
                      {row.totalProd}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 border">
                      {row.autoConsumptionRate}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 border">
                      {row.autoConsumptionRatePrice}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 border">
                      {row.autoConsumptionRateCharge}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 border">
                      {row.resaleRate}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 border">
                      {row.resaleRatePrice}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 border">
                      {row.resaleRateCharge}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 border">
                      {row.annualCharge}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 border">
                      {row.annualBalance}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 border">
                      {row.cumulativeBalance}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tr className="border-b border-neutral-200 font-medium bg-gray-100 ">
                <th scope="col" colSpan={10} className="px-6 py-4 border">
                  Temps de retour sur investissement
                </th>
                <th scope="col" colSpan={2} className="px-6 py-4 border">
                  {investissement}-{investissement + 1} ans
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export function getInvestissementBalancePositive(
  rows: {
    totalProd: string;
    autoConsumptionRate: string;
    autoConsumptionRatePrice: string;
    autoConsumptionRateCharge: string;
    resaleRate: string;
    resaleRatePrice: string;
    resaleRateCharge: string;
    annualCharge: string;
    annualBalance: string;
    cumulativeBalance: string;
  }[]
) {
  return rows.findIndex((row) => parseInt(row.cumulativeBalance) > 0);
}
