import { ChangeEvent } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import Card from "../card";
import Button from "../form-components/Button";
import Field from "../form-components/Field";
import Input from "../form-components/Input";
import SelectInput from "../form-components/SelectInput";
import { SideLogo } from "../form-components/SideLogo";
import ToogleInput from "../form-components/ToogleInput";
import { SubStepperProps } from "../type";

const electricityPrice = 0.26;

const defaultToiture = {
  longueur: "",
  largeur: "",
  orientation: "180",
  typeToiture: "tuileMecanique",
  ombreToiture: false,
};

export default function SolarStep({
  nextStep,
  previousStep,
  Data,
  updateData,
}: SubStepperProps) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      consommationAnnuelle: Data.consommationAnnuelle || 0,
      consommationAnnuelleEur:
        Math.round(Data.consommationAnnuelle * electricityPrice) || 0,
      isPower: Data.isPower || false,
      typeCourant: Data.typeCourant || "monophase",
      toitures: Data.toitures || [defaultToiture],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "toitures",
  });

  const handleConsommationAnnuelleChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(event.target.value);
    if (!isNaN(value)) {
      setValue("consommationAnnuelleEur", Math.round(value * electricityPrice));
    }
  };

  const handleConsommationAnnuelleEurChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(event.target.value);
    if (!isNaN(value)) {
      setValue("consommationAnnuelle", Math.round(value / electricityPrice));
    }
  };

  const onSubmit = (data: any) => {
    updateData({ ...Data, ...data });
    nextStep();
  };

  return (
    <SideLogo>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap mb-6">
          <div className="w-full flex flex-col sm:flex-row items-center justify-center">

            <Field
              label="Consommation annuelle d’électricité en euro"
              error={errors?.consommationAnnuelleEur}
              className="mb-0"
            >
              <Input
                {...register("consommationAnnuelleEur", {
                  required: "Ce champ est obligatoire",
                  valueAsNumber: true,
                })}
                placeholder="Consommation annuelle"
                type="number"
                min={0}
                isError={!!errors?.consommationAnnuelleEur}
                onChange={handleConsommationAnnuelleEurChange}
              />
            </Field>
            <div className="text-center shadow-lg p-2 rounded-md m-2 border-2 border-gray-300">OU</div>
            <Field
              label="Consommation annuelle d’électricité en KWh"
              error={errors?.consommationAnnuelle}
            >
              <Input
                {...register("consommationAnnuelle", {
                  required: "Ce champ est obligatoire",
                  valueAsNumber: true,
                  min: {
                    value: 1,
                    message: "La consommation annuelle doit être supérieure à 0",
                  },
                })}
                placeholder="Consommation annuelle"
                min={0}
                type="number"
                isError={!!errors?.consommationAnnuelle}
                onChange={handleConsommationAnnuelleChange}
              />
            </Field>
          </div>
          <Field label="Type de branchement" className="md:w-full">
            <SelectInput
              {...register(`typeCourant`)}
              options={[
                { label: "monophase", value: "monophase" },
                { label: "triphase", value: "triphase" },
              ]}
            />
          </Field>
          {fields.map((field, index) => (
            <>
              <hr className="w-full my-6" />
              <Card className="mb-6 w-full" latitude={Data.coordonnees.latitude} longitude={Data.coordonnees.longitude} />
              <div key={field.id} className="w-full flex flex-wrap">
                <Field
                  label={`Toiture ${index + 1} - Longueur (en m)`}
                // error={errors?.toitures?.[index]?.longueur.message}
                // error={`toitures.${index}.message`}
                >
                  <Input
                    type="number"
                    step="0.1"
                    min={0}
                    {...register(`toitures.${index}.longueur`, {
                      required: "Ce champ est obligatoire",
                    })}
                    placeholder={`Longueur (en m) - Toiture ${index + 1} `}
                    isError={!!errors?.toitures?.[index]?.longueur}
                  />
                </Field>
                <Field label={`Toiture ${index + 1} - Largeur (en m)`}>
                  <Input
                    type="number"
                    step="0.1"
                    min={0}
                    placeholder={`Largeur (en m) - Toiture ${index + 1} `}
                    {...register(`toitures.${index}.largeur`, {
                      required: "Ce champ est obligatoire",
                    })}
                    isError={!!errors?.toitures?.[index]?.largeur}
                  />
                </Field>
                <Field label={`Toiture ${index + 1} - Orientation`}>
                  <SelectInput
                    {...register(`toitures.${index}.orientation`)}
                    options={[
                      { label: "Sud", value: 0 },
                      { label: "Sud-Ouest", value: 45 },
                      { label: "Sud-Est", value: -45 },
                      { label: "Est", value: -90 },
                      { label: "Ouest", value: 90 },
                      { label: "Nord-Ouest", value: 125 },
                      { label: "Nord-Est", value: -125 },
                      { label: "Nord", value: 180 },
                    ]}
                  />
                </Field>
                <Field label={`Toiture ${index + 1} - Type de toiture`}>
                  <SelectInput
                    {...register(`toitures.${index}.typeToiture`)}
                    options={[
                      { label: "Tuile mécanique", value: "tuileMecanique" },
                      { label: "Tuile canal", value: "tuileCanal" },
                      { label: "Bac acier", value: "bacAcier" },
                      { label: "Fibrociment", value: "fibrociment" },
                      { label: "Toiture terrasse", value: "toitureTerrasse" },
                      { label: "Ardoise", value: "ardoise" },
                    ]}
                  />
                </Field>
                <Field label={`Toiture ${index + 1} - Ombre sur la toiture`}>
                  <ToogleInput
                    {...register(`toitures.${index}.ombreToiture`)}
                  />
                </Field>
                {index > 0 && (
                  <Button type="button" onClick={() => remove(index)}>
                    Supprimer cette toiture
                  </Button>
                )}
                <br />
              </div>
            </>
          ))}
        </div>
        <div className="grid grid-cols-3">
          <Button type="button" onClick={() => append(defaultToiture)}>
            Ajouter une toiture
          </Button>
          <Button onClick={() => previousStep()}>Retour</Button>
          <Button type="submit">Suivant</Button>
        </div>
      </form>
    </SideLogo>
  );
}
