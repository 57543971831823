import { cn } from "../lib/utils";

interface CardProps {
    latitude: number;
    longitude: number;
    className?: string;
}

export default function Card({ latitude, longitude, className }: CardProps) {
    return (
        <div
            className={cn(`relative rounded-lg overflow-hidden`, className)}
        >
            <Pole>

                <iframe
                    className="w-full"
                    height={400}
                    frameBorder={0}
                    scrolling="no"
                    marginHeight={0}
                    marginWidth={0}
                    sandbox="allow-forms allow-scripts allow-same-origin"
                    src={`https://www.geoportail.gouv.fr/embed/visu.html?c=${longitude},${latitude}&z=19&l0=ORTHOIMAGERY.ORTHOPHOTOS::GEOPORTAIL:OGC:WMTS(1)&permalink=yes`}
                    allowFullScreen
                ></iframe>

            </Pole>
        </div>
    )
}

function Pole({ children }: { children: React.ReactNode }) {
    const style = "border-2 border-black p-2 rounded-lg bg-white/50"
    return (
        <div className="flex flex-col items-center justify-center">
            {children}
            <div className={`absolute top-8 left-0 left-1/2 ${style}`}>Nord</div>
            <div className={`absolute bottom-16 left-0 left-1/2 ${style}`}>Sud</div>
            <div className={`absolute top-0 right-8 top-1/2 ${style}`}>Est</div>
            <div className={`absolute top-0 left-8 top-1/2 ${style}`}>Ouest</div>
        </div>
    )
}