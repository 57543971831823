import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";
import AdressStep from "./Steps/AdressStep";
import FinalStep from "./Steps/FinalStep";
import InfoStep from "./Steps/InfoStep";
import SolarStep from "./Steps/SolarStep";
import BarStep from "./form-components/BarStep";
import ProgressBar from "./form-components/ProgressBar";
import { typeDataForm } from "./type";

const queryClient = new QueryClient();

export const DEBUG = process.env.REACT_APP_URL_DEBUG === "True";

const defaultData: typeDataForm = DEBUG
  ? {
    name: "Dupont",
    firstname: "Fabien",
    address: "25 rue frédéric chopin",
    plz: "31200",
    city: "Toulouse",
    coordonnees: { latitude: 0, longitude: 0 },

    typeCourant: "monophase",
    consommationAnnuelle: 3000,
    email: "fabien@cool.com",
    phone: "0612345678",
  }
  : {
    name: "",
    firstname: "",
    address: "",
    plz: "",
    city: "",
    coordonnees: { latitude: 0, longitude: 0 },
    typeCourant: "monophase",
    consommationAnnuelle: 0,
    email: "",
    phone: "",
  };

function MultiStep() {
  const [currentStep, setCurrentStep] = useState(0);
  const [Data, setData] = useState<typeDataForm>(defaultData);

  const steps = [AdressStep, SolarStep, InfoStep, FinalStep];
  const stepsName = ["Adresse", "Toiture", "Informations", "Devis"];
  const StepComponent = steps[currentStep];

  const nextStep = () =>
    setCurrentStep(Math.min(currentStep + 1, steps.length - 1));

  const previousStep = () => setCurrentStep(Math.max(currentStep - 1, 0));

  const updateData = (newData: Partial<FormData>) =>
    setData({ ...Data, ...newData });

  return (
    <QueryClientProvider client={queryClient}>
      <ProgressBar step={currentStep} steps={stepsName} />
      <BarStep step={currentStep} steps={stepsName} />
      <StepComponent
        nextStep={nextStep}
        previousStep={previousStep}
        Data={Data}
        updateData={updateData}
      />
      {/* {Data && <pre>{JSON.stringify(Data, null, 2)}</pre>} */}
    </QueryClientProvider>
  );
}

export default MultiStep;
